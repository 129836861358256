<template>
  <div class="pbf">
    <BrandTabGallery
      :inurl="`/${props.type}/${props.id}/gallery`"
      :intags="tags"
    /> 
  </div>
</template>

<script setup>

const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  },
  type: {
    type: String
  }
})
const tags = ref($tagsUtil.tagsBrandSeedGallery);

</script>

<style scoped></style>
